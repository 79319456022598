import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Civil Engineering Drawings`}</h1>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Building-Drawing-Basic-Definitions.pptx"
      }}>{`Building Drawing Basic Definitions`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Building_Drawing-Plan-and-Section.pdf"
      }}>{`Building_Drawing Plan and Section`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Scale_in_Drawing.pdf"
      }}>{`Scale_in_Drawing`}</a></p>
    <h2>{`Building drawing basics (AUTOCAD Special)`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/AutoCAD_Shortcuts-guide.pdf"
      }}>{`AutoCAD_Shortcuts guide`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Doors.pptx"
      }}>{`Doors`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Finalizing-the-Section-drawing-of-House-plan.pptx"
      }}>{`Finalizing the Section drawing of House plan`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Guidelines-for-the-designing-of-House-Plans.pptx"
      }}>{`Guidelines for the designing of House Plans`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Introduction-to-House-Plan-Part-1.pptx"
      }}>{`Introduction to House Plan Part-1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Introduction-to-House-Plan-Part-2.pptx"
      }}>{`Introduction to House Plan Part-2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Introduction-to-layers.ppt"
      }}>{`Introduction to layers`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Khasra-Plans.pptx"
      }}>{`Khasra Plans`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/LDA-Building-plan-approval-process.pptx"
      }}>{`LDA Building plan approval process`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Reccomended-sizes-for-house-plan-components-and-FAR-Requirements.pptx"
      }}>{`Reccomended sizes for house plan components and FAR Requirements`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Site-Requirements-Residential-Buildings.pptx"
      }}>{`Site Requirements Residential Buildings`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Windows.pptx"
      }}>{`Windows`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Elevations-drawing2.pptx"
      }}>{`Elevations drawing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Section-drawing1.pptx"
      }}>{`Section drawing`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/working-with-rotated-background.pdf"
      }}>{`working with rotated background`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Learn-AUTOCAD-in-urdu-1.pdf"
      }}>{`Learn AUTOCAD in urdu`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Learn-AUTOCAD-in-urdu.pdf"
      }}>{`Learn AUTOCAD in urdu`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/how-to-trim-a-line.pdf"
      }}>{`how-to-trim-a-line`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/how-to-extend-a-line.pdf"
      }}>{`how-to-extend-a-line`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/how-to-draw-a-circle.pdf"
      }}>{`how-to-draw-a-circle`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/how-to-pan-and-zoom.pdf"
      }}>{`how to pan-and-zoom`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/how-to-draw-a-line.pdf"
      }}>{`how to draw-a-line`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Creating-an-array-of-objects.pdf"
      }}>{`Creating an array of objects`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/creating-a-fillet.pdf"
      }}>{`creating a fillet`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/cad-table.pdf"
      }}>{`cad-table`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/cad-layers.pdf"
      }}>{`cad-layers`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/CAD-Tutorial-Fangfang-110227.pdf"
      }}>{`CAD Tutorial`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/block-attribute.pdf"
      }}>{`block-attribute`}</a></p>
    <p><a parentName="p" {...{
        "href": ""
      }}>{`AUTOCAD tutorial`}</a><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/AUTOCAD-tutorial.pdf"
      }}>{`https://seismicconsolidation.com/wp-content/uploads/2020/05/AUTOCAD-tutorial.pdf`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/autocad-Setting-a-New-Drawing-Different-Coordinate-Systems.pdf"
      }}>{`autocad Setting a New Drawing & Different Coordinate Systems`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Autocad-introduction.pptx"
      }}>{`Autocad introduction`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/autocad-editing-commands.pdf"
      }}>{`autocad editing commands`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/autocad-drawing-commands.pdf"
      }}>{`autocad drawing commands`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/autocad-Command-Basics.pdf"
      }}>{`autocad Command Basics`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/05/Autocad-3d-training-manual.pdf"
      }}>{`Autocad 3d training manual`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      